import {RiskProfilingService} from 'components/service/risk-profiling.service';
import nxModule from 'nxModule';
import $ from 'jquery';
import systemPropertyService from '../../../react/system/systemPropertyService';
import ReportService from 'report/ReportService';

const title = 'Nextbank';
const templateUrl = require('./menu.template.html');

nxModule.component('menu', {
  templateUrl,
  controller: function ($sce, $scope, $location, $interval, $timeout, $element, $compile, $route,
                        nav, confirmation, customerCache, customerContextService, groupCustomerCache,
                        authentication, availableTasksCache, config, breadcrumbs,
                        cashTransferCheckService, command, $rootScope, appVersionService, userCache,
                        centerService, http) {

    this.centerService = centerService;
    this.reportService = new ReportService();
    this.systemPropertyService = systemPropertyService;
    this.$route = $route;
    this.customerCache = customerCache;
    this.http = http;
    this.riskProfilingService = new RiskProfilingService();

    $scope.customerContextService = customerContextService;
    $scope.customerCache = customerCache;
    $scope.groupCustomerCache = groupCustomerCache;
    
    appVersionService.backend()
      .then(backendVersionObject => {
        const frontendVersion = extractVersionNumber(appVersionService.currentFrontend().appVersion);
        const backendVersion = extractVersionNumber(backendVersionObject.appVersion);

        this.versionInfo = `B:${backendVersion}/F:${frontendVersion}`;
      });

    const extractVersionNumber = (fullVersionCode) => {
      const versionRegex = /\d+(\.\d+)+(-S)?/;
      const match = versionRegex.exec(fullVersionCode);
      return match ? match[0] : 'unknown';
    }

    this.technicalUser = false;
    userCache.withParam().toPromise()
      .then(users => {
        const userId = authentication.context.id;
        const user = users.find(u => u.id === userId);
        this.technicalUser = user && user.technical;
      });

    const getTitle = () => {
      const emptyLabel = '<null>';
      const crumbs = breadcrumbs.get();
      let index = crumbs.length - 1;
      let label = emptyLabel;
      while (index >= 0 && label === emptyLabel) {
        label = crumbs[index--].label;
      }
      return label !== emptyLabel ? `${title} - ${label.toLowerCase()}` : title;
    };

    $scope.$on('$routeChangeSuccess', () => {
      $scope.$watch(
        () => getTitle(),
        () => $('title').text(getTitle()),
        true
      );
    });

    let interval = null;
    if (authentication.permissions['ACTIONS_READ']) {
      availableTasksCache.toObservable().subscribe(data => {
        this.availableTasks = data;
      });

      // If actions are shown and task poll is enabled -> refetch pending actions count
      const taskPollEnabled = systemPropertyService.getProperty('TASK_COUNTER_POLL_ENABLED');
      // Disable task counter polling by default
      interval = null;
      if (taskPollEnabled === 'TRUE') {
        interval = $interval(() => {
          availableTasksCache.refetch();
        }, config.actionCounterFetchInterval);
      }
    }

    const sidenavTabClass = 'sidenav__tab';
    const sidenavActionsTabClass = `${sidenavTabClass}--actions`;

    $scope.setActiveTabClass = tab => {
      const tabRegExp = new RegExp(`.*${tab}.*`);
      const selectedTab = replaceCustomerId($location.path());
      return tabRegExp.test(selectedTab) ? `${sidenavTabClass}--selected` : '';
    };

    const blockActionsSubscribe = cashTransferCheckService.toObservable()
      .subscribe(shouldBlockActions => {
        const $sidenavTabs = $(`.${sidenavTabClass}`);
        $sidenavTabs.not(`.${sidenavActionsTabClass}`).prop('disabled', shouldBlockActions);
      });

    $scope.selectTab = tab => $location.path(tab);
    $scope.selectCustomerTab = tab => $scope.selectTab(replaceCustomerId(tab));
    $scope.selectGroupCustomerTab = tab => $scope.selectTab(replaceGroupCustomerId(tab));

    const replaceIndividualOrGroupCustomerId = (tab, loadedCustomerId) => {
      const customerIdParam = ':customerId';
      if (!tab || tab.indexOf(customerIdParam) === -1 || !loadedCustomerId) {
        return tab;
      }

      return tab.replace(customerIdParam, loadedCustomerId);
    }

    const replaceCustomerId = tab => replaceIndividualOrGroupCustomerId(tab, customerContextService.customerId);
    const replaceGroupCustomerId = tab => replaceIndividualOrGroupCustomerId(tab, groupCustomerCache.loadedCustomerId);

    $scope.refreshCustomer = ($event, cache) => {
      confirmation('Are you sure you want to refresh the context?', () => cache.refetch());
      $event.stopPropagation();
    };

    $scope.closeCustomer = ($event, cache) => {
      confirmation('Are you sure you want to close the context?', () => {
        cache.unloadCustomer();
        $location.path('/dashboard/lookup-customer');
      });
      $event.stopPropagation();
    };

    this.refreshCenterContext = async ($event) => {
      $event.stopPropagation();
      const confirmed = await confirmation('Are you sure you want to refresh the center context?');
      if (confirmed && $location.path().startsWith('/center')) {
        $route.reload();
      }
    };

    this.closeCenterContext = async ($event) => {
      $event.stopPropagation();
      const confirmed = await confirmation('Are you sure you want to close the center context?');
      if (confirmed) {
        centerService.unloadContext();
        $location.path('/dashboard/lookup-center');
      }
    };

    const sectionClass = 'sidenav-section';
    const sectionHeaderClass = `${sectionClass}__header`;
    const sectionEntriesClass = `${sectionClass}__entries`;
    const sectionToggledHeaderClass = `${sectionHeaderClass}--toggled`;


    const hideEmptySectionEntries = sectionElement => {
      if ($(sectionElement).data('unobserved')) {
       return;
      }

      const sectionEntries = $(sectionElement).find(`.${sectionEntriesClass}`);
      const visibleChildren = $(sectionEntries).find(':visible');
      if (visibleChildren.length === 0) {
        $(sectionElement).hide();
      }
    };

    // hide all empty section entries
    $timeout(() => {
      $(`.${sectionClass}`).each((index, section) => {
        hideEmptySectionEntries(section)
      })
    });

    // observe menu sections children visibility changes and remove the sections respectively
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const section = $(mutation.target).closest(`.${sectionClass}`);
        hideEmptySectionEntries(section);
      });
    });

    let sectionObservers = [];
    $(`.${sectionClass}`).each((index, section) => {
      sectionObservers.push(mutationObserver.observe(section, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['style'],
      }));
    });

    $(document).click(`.${sectionHeaderClass}`, event => {
      const $sidenavHeader = $(event.target).closest(`.${sectionHeaderClass}`);
      $sidenavHeader.toggleClass(sectionToggledHeaderClass);
      $sidenavHeader.siblings(`.${sectionEntriesClass}`).collapse('toggle');
    });

    this.$onInit = async () => {
      await this.prepareSupportUrl();

      const customerId = this.$route.current.params['customerId'];
      if (customerId) {
        const {branchId} = await this.customerCache.profile(customerId).toPromise();
        const customerBranch = await this.http.get(`/management/branches/${branchId}`).toPromise();
        this.isDisplayRiskProfiling = this.riskProfilingService.getDisplayRiskProfiling(customerBranch.code);
      }
    }

    this.prepareSupportUrl = async () => {
      const SUPPORT_PREFIX = `https://support.`;
      const DOMAIN_PREFIX_TO_TRIM = /^(private\.)/;
      const hostname = window.location.hostname.replace(DOMAIN_PREFIX_TO_TRIM, '');
      const users = await userCache.withParam().toPromise();
      const currentUser = users.find(user => user.id === authentication.context.id);

      let supportFormUrl = `${SUPPORT_PREFIX}${hostname}`;

      if (currentUser) {
        const {fullName, email} = currentUser;
        const nameParam = fullName ? `?name=${fullName}` : '';
        const nextQueryPrefix = nameParam ? '&' : '?'
        const emailParam = email ? `${nextQueryPrefix}email=${email}` : '';
        supportFormUrl = `${supportFormUrl}${nameParam}${emailParam}`;
      }

      $scope.trustedSupportFormUrl = $sce.trustAsResourceUrl(supportFormUrl);
    }

    this.$onDestroy = () => {
      if (interval) {
        $interval.cancel(interval);
      }
      blockActionsSubscribe.unsubscribe();
      sectionObservers.forEach(observer => observer.disconnect())
    };

    let reportNames = systemPropertyService.getProperty("CUSTOM_REPORT_NAMES");
    this.reportService.readAvailableFileReports().then(reports => {
      this.hasReportToolEnabled = reports.length > 0 && reportNames.length > 0;
    })
  }
});
