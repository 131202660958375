import systemPropertyService from 'system/systemPropertyService';

export class RiskProfilingService {

  async getDisplayRiskProfiling(branchCode) {
    const isRiskProfilingEnabled = systemPropertyService.getProperty('RISK_PROFILING_ENABLED') === 'TRUE';
    const branchCodeStringValue = systemPropertyService.getProperty('RISK_PROFILING_SELECTED_BRANCH_CODES');
    const riskProfilingSelectedBranchCodes = branchCodeStringValue.split(',').map(o => o.trim());
    const isBranchFilterDisabled = riskProfilingSelectedBranchCodes[0] === '';
    const isBranchCodeIncluded = riskProfilingSelectedBranchCodes[0] !== ''
      && riskProfilingSelectedBranchCodes.includes(branchCode);

    return isRiskProfilingEnabled && (isBranchFilterDisabled || isBranchCodeIncluded);
  }
}
